import { Controller } from "stimulus"

export default class extends Controller {
  static classes = [
    "hidden",
    "hiddenIndicator",
    "visibleIndicator",
  ]

  static targets = [
    "content",
    "indicator",
  ]

  toggle() {
    const contentHidden = this.contentTarget.classList.contains(this.hiddenClass)

    if (contentHidden) {
      this.contentTarget.classList.remove(this.hiddenClass)
    } else {
      this.contentTarget.classList.add(this.hiddenClass)
    }

    if (this.hasIndicatorTarget) {
      if (contentHidden) {
        this.indicatorTarget.classList.remove(this.hiddenIndicatorClass)
        this.indicatorTarget.classList.add(this.visibleIndicatorClass)
      } else {
        this.indicatorTarget.classList.remove(this.visibleIndicatorClass)
        this.indicatorTarget.classList.add(this.hiddenIndicatorClass)
      }
    }
  }
}
