import { Controller } from "stimulus"
import SlimSelect from "slim-select"
import debounce from "lodash.debounce"

export default class extends Controller {
  static values = {
    url: String,
    textAttr: String,
    valAttr: String,
    placeholder: String,
    options: Object,
    addable: Boolean
  }

  initialize() {
    this.fetchRecords = debounce(this.fetchRecords, 300).bind(this)
  }

  fetchRecords(query, callback) {
    if (query === "") {
      callback(false)
      return
    }

    fetch(`${this.urlValue}?query=${query}`)
      .then(response => response.json())
      .then(data => {
        const options = data.map(record => {
          return {
            text: record[this.textAttrValue || "name"],
            value: record[this.valAttrValue || "id"]
          }
        })

        callback(options)
      })
      .catch((error) => {
        console.log(error)
        callback(false)
      })
  }

  connect() {
    new SlimSelect({
      select: this.element,
      allowDeselect: true,
      ...(this.hasUrlValue && { ajax: this.fetchRecords }),
      ...(this.addableValue && { addable: (value) => value }),
      ...this.optionsValue
    })
  }
}
