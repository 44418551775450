import { Controller } from "stimulus"
import noUiSlider from "nouislider"
import "nouislider/distribute/nouislider.css"
import { secondsToMmSs } from "../utils/time"

const formats = {
  int: {
    to: (num) => parseFloat(num).toFixed(0),
    from: (num) => parseFloat(num).toFixed(0),
  },
  time: {
    to: (seconds) => secondsToMmSs(seconds),
    from: (seconds) => seconds,
  },
  default: {
    to: (val) => val,
    from: (val) => val,
  },
}

export default class extends Controller {
  static values = {
    options: Object,
    format: String,
  }

  static targets = ["minInput", "maxInput"]

  connect() {
    const _this = this
    const isTimeFormat = this.formatValue === "time"
    const tooltips = isTimeFormat ? [formats.time, formats.time] : true
    const format = isTimeFormat
      ? false
      : { format: formats[this.formatValue] || formats.default }

    noUiSlider.create(this.element, {
      ...this.optionsValue,
      tooltips,
      ...format,
    })

    this.element.noUiSlider.on("change", (values, _handle) => {
      const [minVal, maxVal] = values
      const { min, max } = _this.optionsValue.range

      if (minVal == min && maxVal == max) {
        _this.minInputTarget.disabled = true
        _this.maxInputTarget.disabled = true
      } else {
        _this.minInputTarget.disabled = false
        _this.maxInputTarget.disabled = false
        _this.minInputTarget.value = minVal
        _this.maxInputTarget.value = maxVal
      }

      // programmatically updating an inputs value doesn't trigger a "change" event
      // so this has to manually be done via in order for the
      // data-action="change->song-filter#submitForm" action to happen
      const event = new Event("change")
      _this.minInputTarget.dispatchEvent(event)
    })
  }
}
