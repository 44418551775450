import { fetchAndPlayAudio } from "./utils/requests"

$(document).on("turbolinks:load", () => {
  function bindPlayButtons() {
    document.querySelectorAll(".custom-play-button").forEach(button => {
      button.addEventListener("click", function() {
        $(".marketplace-player").removeClass("is-hidden")
        const player = document.getElementById("marketplace_audio_player")
        const trackId = button.getAttribute("data-track-id")
        const title = button.getAttribute("data-title")
        const artist = button.getAttribute("data-artist")
        const songId = button.getAttribute("data-song-id")
        const playerTitleElement = document.getElementById("player-title")
        const playerTitle = playerTitleElement.innerText || playerTitleElement.textContent
        const isPlaying = !player.paused && !player.ended && playerTitle.includes(title)

        if (isPlaying) {
          player.pause()
          button.classList.remove("amplitude-playing")
          button.classList.add("amplitude-paused")
        } else {
          playerTitleElement.innerHTML = `<a href="/admin/marketplace/songs/${songId}">${title}</a>`
          document.getElementById("player-artist").textContent = artist

          // If clicking play button on song that is already loaded, just resume play.
          if (playerTitle.includes(title)) {
            player.play()
          }

          // Pause the current playing song if it's different from the clicked one
          if (!player.paused && !player.ended && !playerTitle.includes(title)) {
            const currentButton = document.querySelector(".amplitude-playing")
            if (currentButton) {
              currentButton.classList.remove("amplitude-playing")
              currentButton.classList.add("amplitude-paused")
            }
          }

          // Load new song
          if (!playerTitle.includes(title)) {
            fetchAndPlayAudio(trackId, player)
            setTimeout(() => {
              button.classList.remove("amplitude-paused")
              button.classList.add("amplitude-playing")
            }, 250)
          }
        }
      })
    })
  }

  function syncPlayerWithButtons() {
    const player = document.getElementById("marketplace_audio_player")

    player.addEventListener("play", () => {
      document.querySelectorAll(".custom-play-button").forEach(button => {
        const title = button.getAttribute("data-title")
        const playerTitleElement = document.getElementById("player-title")
        const playerTitle = playerTitleElement.innerText || playerTitleElement.textContent
        if (playerTitle.includes(title)) {
          button.classList.remove("amplitude-paused")
          button.classList.add("amplitude-playing")
        } else {
          button.classList.remove("amplitude-playing")
          button.classList.add("amplitude-paused")
        }
      })
    })

    player.addEventListener("pause", () => {
      document.querySelectorAll(".custom-play-button").forEach(button => {
        const title = button.getAttribute("data-title")
        const playerTitleElement = document.getElementById("player-title")
        const playerTitle = playerTitleElement.innerText || playerTitleElement.textContent
        if (playerTitle.includes(title)) {
          button.classList.remove("amplitude-playing")
          button.classList.add("amplitude-paused")
        }
      })
    })

    player.addEventListener("ended", () => {
      document.querySelectorAll(".custom-play-button").forEach(button => {
        button.classList.remove("amplitude-playing")
        button.classList.add("amplitude-paused")
      })
    })
  }

  bindPlayButtons()
  syncPlayerWithButtons()
})
