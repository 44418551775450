$(document).on("turbolinks:load", () => {
  const offerForm = $(".offer-form")
  if (offerForm.length === 0) return false

  offerForm.on("submit", (e) => {
    const discountPercentInput = $("#offer_coupons_attributes_0_discount_percentage")
    if (discountPercentInput.length === 0) return false

    const submitButton = offerForm.find("input[type='submit']")
    const discountPercent = parseFloat(discountPercentInput.val())
    const videoPlanSelected = offerForm
      .find("#offer_coupons_attributes_0_plan_ids")
      .val()
      .some(planId => planId.includes("video"))

    submitButton.prop("disabled", true)

    if (videoPlanSelected && discountPercent > 20) {
      const confirmation = confirm("Video plan discounts in excess of 20% must be approved by Finance. Are you sure you want to do this?")
      if (!confirmation) {
        e.preventDefault()
        submitButton.prop("disabled", false)
      }
    }
  })
})
