import { Controller } from "stimulus"
import Sortable from "sortablejs"
import { ajax } from "@rails/ujs"

export default class extends Controller {
  static values = {
    handle: String,
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      handle: this.handleValue || undefined,
      onEnd: this.end.bind(this),
    })
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  end({ item, newIndex }) {
    if (!item.dataset.sortableUpdateUrl || !window._rails_loaded) return
    ajax({
      url: item.dataset.sortableUpdateUrl,
      type: "PATCH",
      data: `position=${newIndex}`,
      error: function (_data) {
        alert("Sorting error!")
      },
    })
  }
}
