import { fetchAndPlayAudio } from "./utils/requests"

$(document).on("turbolinks:load", () => {
  const player = document.getElementById("marketplace_show_audio_player")
  const playButton = document.getElementById("play_song_button")

  if (playButton) {
    playButton.addEventListener("click", function() {
      const trackId = playButton.getAttribute("data-track-id")
      fetchAndPlayAudio(trackId, player)
    })
  }
})
