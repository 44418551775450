import { Controller } from "stimulus"

export default class extends Controller {
  static values = { toggleClass: String }

  static targets = [
    "content",
    "input"
  ]

  toggle(e) {
    const { selectedIndex } = e.target

    this.contentTargets.forEach((el, i) => {
      if (i === selectedIndex) {
        el.classList.remove(this.toggleClassValue)
      } else {
        el.classList.add(this.toggleClassValue)
      }
    })

    if (this.hasInputTarget) {
      this.inputTargets.forEach((input, i) => {
        input.disabled = i !== selectedIndex
      })
    }
  }
}
