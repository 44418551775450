document.addEventListener("turbolinks:load", () => {
  const container = document.getElementById("generate-daaci-csv-container")
  if (!container) return

  const toggleLink = document.getElementById("generate-daaci-csv-form-toggle")
  const toggleDiv = document.getElementById("generate-daaci-csv-form-div")
  const form = document.getElementById("generate-daaci-csv-form")
  const fileField = document.getElementById("csv_upload")
  const submitButton = document.getElementById("generate-daaci-csv-form-submit-button")

  // Toggle the form visibility
  const toggleFormVisibility = (event) => {
    event.preventDefault()
    container.classList.toggle("box")
    toggleDiv.classList.toggle("is-hidden")
  }

  // Enable the submit button if the file field has a value
  const enableSubmitButton = () => {
    if (fileField.value) {
      submitButton.removeAttribute("disabled")
    }
  }

  // Handle form submission
  const handleFormSubmission = (event) => {
    event.preventDefault()
    form.submit()
    fileField.value = ""
    submitButton.setAttribute("disabled", true)
  }

  // Event listeners
  toggleLink.addEventListener("click", toggleFormVisibility)
  fileField.addEventListener("change", enableSubmitButton)
  form.addEventListener("submit", handleFormSubmission)

  // Initial state
  submitButton.setAttribute("disabled", true)
})
