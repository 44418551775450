import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    param: String
  }

  static targets = [
    "clearFilters",
    "filterBy",
    "form",
  ]

  toggle(e) {
    const { tagId } = e.srcElement.parentElement.dataset
    const filterName = e.target.nextSibling.innerText
    const tagEl = this.tagTemplate(filterName)

    if (e.target.checked) {
      if (this.hasClearFiltersTarget) {
        $(this.clearFiltersTarget).before($(tagEl))
      } else {
        $(this.filterByTarget).append($(tagEl))
      }
    } else {
      $(`[parent-tag-id='${tagId}'] input`).prop("checked", false)
      $(this.filterByTarget).remove($(tagEl))
    }

    this.submitForm()
  }

  clearFilter(e) {
    const { param, value } = e.target.dataset

    switch (param) {
      case "bpm":
      case "duration":
      case "vocals":
        this.clearFilterType(e)
        break
      default:
        $(`input[name="q[${param}][]"][value="${value}"]`).prop("checked", false)
        $(`[parent-tag-id='${value}'] input`).prop("checked", false)
        break
    }

    this.submitForm()
  }

  clearFilterType(e) {
    const { param, category } = e.target.dataset

    switch (param) {
      case "bpm":
      case "duration":
        const max = param === "bpm" ? 300 : 480
        $(`input[name="q[${param}][min]"]`).val(0).prop("disabled", true)
        $(`input[name="q[${param}][max]"]`).val(max).prop("disabled", true)
        break
      default:
        const filterInputs = category
          ? $(`input[name="q[${param}][]"].${category}`)
          : $(`input[name="q[${param}][]"]`)

        filterInputs.prop("checked", false)
        break
    }

    this.submitForm()
  }

  tagTemplate(content) {
    return `
      <div class="control">
        <span class="tag is-small">
          ${content}
        </span>
      </div>
    `
  }

  submitForm() {
    // form must be submitted via click to work with turbolinks and avoid page reload
    $(this.formTarget).find(":submit").click()
  }
}
