import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.formTarget.addEventListener("submit", () => {
      const player = document.getElementById("turbolinks-player-id")
      player.dataset.scrollTop = document.scrollingElement.scrollTop
    })
  }
}
