import { Controller } from "stimulus"

const FORM_ID = "ss-bulkd-download-form"
const IFRAME_ID = "ss-bulk-download-iframe"
const DOWNLOAD_CLASS = "ss-bulk-download"

export default class extends Controller {
  static targets = ["content", "indicator"]

  static values = {
    playlistId: String,
  }

  download() {
    this.clearOldForm()

    fetch(`/admin/playlists/${this.playlistIdValue}/download_media`)
      .then((res) => res.json())
      .then((resp) => {
        const form = document.createElement("form")
        form.id = FORM_ID
        form.action = resp.url
        form.method = "post"
        form.target = IFRAME_ID
        form.className = DOWNLOAD_CLASS

        const iframe = document.createElement("iframe")
        iframe.name = IFRAME_ID
        iframe.id = IFRAME_ID
        iframe.style.display = "none"
        iframe.className = DOWNLOAD_CLASS

        const data = document.createElement("input")
        data.type = "hidden"
        data.name = "data"
        data.value = resp.payload.data

        const signature = document.createElement("input")
        signature.type = "hidden"
        signature.name = "signature"
        signature.value = resp.payload.signature

        document.body.appendChild(form)
        document.body.appendChild(iframe)
        form.appendChild(data)
        form.appendChild(signature)
        form.submit()

        while (form.firstChild) {
          form.removeChild(form.firstChild)
        }
      })
  }

  clearOldForm() {
    const form = document.getElementById(FORM_ID)
    const iframe = document.getElementById(IFRAME_ID)
    if (form) document.body.removeChild(form)
    if (iframe) document.body.removeChild(iframe)
  }
}
