import { Controller } from "stimulus"

const VIDEO_ID_REGEX =
  /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=(?:live\/)?|\/)([^\s&]+)/

export default class extends Controller {
  static targets = [
    "form",
    "urlField",
    "channelIdField",
    "titleField",
    "videoIdField",
  ]

  submit(e) {
    e.preventDefault()
    const videoId = this.urlFieldTarget.value.match(VIDEO_ID_REGEX)?.[1]
    this.fetchVideo(videoId)
      .then((data) => this.updateFormFields(data, videoId))
      .then(() => this.formTarget.submit())
  }

  fetchVideo(id) {
    return fetch(
      `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=${process.env.YOUTUBE_API_KEY}&part=snippet`
    )
      .then((response) => response.json())
      .then((data) => {
        return data.items[0]?.snippet || { title: "Untitled video" }
      })
  }

  updateFormFields(data, videoId) {
    this.titleFieldTarget.value = data.title
    if (data.channelId) this.channelIdFieldTarget.value = data.channelId
    this.videoIdFieldTarget.value = videoId
  }
}
