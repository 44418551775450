import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import XHRUpload from "@uppy/xhr-upload"
import prettyBytes from "pretty-bytes"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"

$(document).on("turbolinks:load", () => {
  if ($("#upload-documents").length === 0) return false
  const authToken = $("#document-auth-token").val()
  const uppy = new Uppy({ debug: true })

  const documentTableRow = (filename, filesize) => {
    return `
      <tr>
        <td class="is-vcentered">
          <div class="title is-6">
            <a>${filename}</a>
          </div>
          <div class="subtitle is-7">${filesize}</div>
        </td>
        <td class="is-vcentered"></td>
        <td class="is-vcentered">
          <div class="btn-row">
            <button class="button is-light is-link">Edit</button>
            <button class="button is-light is-danger">Delete</button>
          </div>
        </td>
      </tr>
    `
  }

  uppy
    .use(XHRUpload, {
      formData: true,
      fieldName: "document[file]",
      endpoint: window.location.pathname,
      metaFields: ["filename", "filesize", "authenticity_token"]
    })
    .use(Dashboard, {
      trigger: "#upload-documents",
      showProgressDetails: true,
      note: "Documents uploaded here are available to all team owners.",
      hideRetryButton: true,
      browserBackButtonClose: false,
      metaFields: [
        { id: "filename", filename: "Filename", placeholder: "Filename" },
      ],
    })
    .on("file-added", (file) => {
      uppy.setFileMeta(file.id, {
        filename: file.name,
        filesize: prettyBytes(file.size),
        authenticity_token: authToken,
      })
    })
    .on("upload-success", (file, response) => {
      const filename = response.body.name
      const filesize = prettyBytes(file.size)
      const lastTableRow = $("#documents-table tr:last")
      $(documentTableRow(filename, filesize)).insertAfter(lastTableRow)
      $(".no-documents").remove()
    })
    .on("upload-error", (file, _error, response) => {
      uppy.info(`${file.name}: ${response.body.error}`, "error")
    })
    .on('dashboard:modal-closed', () => location.reload())
})
