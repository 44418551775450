import Tagify from "@yaireo/tagify"
import Sortable from "sortablejs"
import cx from "classnames"

$(document).on("turbolinks:load", () => {
  const inputFields = $(".song-tags")
  if (inputFields.length === 0) return false

  const renderSuggestionsList = (tagify) => {
    tagify.dropdown.show.call(tagify)
    tagify.DOM.scope.parentNode.appendChild(tagify.DOM.dropdown)
  }

  const tagTemplate = (tagData) => {
    return `
      <tag title="${tagData.value}" contenteditable="false" spellcheck="false" class="tagify__tag ${cx(tagData.class)}">
        <x title="remove tag" class="tagify__tag__removeBtn"></x>
        <div>
          <span class="tagify__tag-text">${tagData.name}</span>
        </div>
      </tag>
    `
  }

  const dropdownItemTemplate = (tagData) => {
    return `
      <div class="tagify__dropdown__item ${cx(tagData.class)}" tagifySuggestionIdx="${tagData.tagifySuggestionIdx}">
        <span>${tagData.name}</span>
      </div>
    `
  }

  const taggableTagInputs = (index, likenessRank, tagId, taggableType) => {
    const namePrefix = taggableType === "Song" ? "song" : "marketplace_song"

    return `
      <div data-tag-id="${tagId}" data-index="${index}" class="added-tag">
        <input type="hidden" name="${namePrefix}[taggable_tags_attributes][${index}][tag_id]" value="${tagId}">
        <input type="hidden" name="${namePrefix}[taggable_tags_attributes][${index}][taggable_type]" value="${taggableType}">
        <input type="hidden" name="${namePrefix}[taggable_tags_attributes][${index}][likeness_rank]" value="${likenessRank}">
      </div>
    `
  }

  inputFields.each(function() {
    const field = $(this)
    const whitelist = field.data("tags")
    const category = field.data("category")
    const taggableType = field.data("model")

    const tagify = new Tagify(field[0], {
      whitelist: whitelist,
      enforceWhitelist: true,
      templates: {
        tag: tagTemplate,
        dropdownItem: dropdownItemTemplate
      },
      dropdown: {
        position: "manual",
        maxItems: Infinity,
        enabled: 0,
        classname: "song-tags-list",
        searchKeys: ["name", "value"]
      }
    })

    new Sortable(tagify.DOM.scope, {
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)",
      forceFallback: true,
      filter: "." + tagify.settings.classNames.input,
      draggable: "." + tagify.settings.classNames.tag,
      onEnd: (e) => {
        tagify.updateValueByDOMTags()
        const hiddenTagInputs = $(e.target).prev()

        // updates hidden likeness_rank input fields to match tagify input order
        $(tagify.DOM.scope).children("tag").each(function(index) {
          const tagId = $(this).attr("title")
          hiddenTagInputs
            .find(`div[data-tag-id="${tagId}"] .likeness-rank-input`)
            .prop("value", index + 1)
        })
      }
    })

    tagify
      .on("add", ({ detail }) => {
        const tagId = detail.data.value
        const likenessRank = detail.index + 1
        const hiddenTagInputs = $(`div[data-tag-id="${tagId}"]`)
        const index = $(".added-tag").length > 0
          ? ($(".added-tag").last().data("index") || 0) + 1
          : ($(".existing-tag").last().data("index") || 0) + 1

        if (hiddenTagInputs.hasClass("existing-tag")) {
          hiddenTagInputs.children(".destroy-checkbox").prop("checked", false)
          hiddenTagInputs.children(".likeness-rank-input").val(likenessRank)
        } else {
          const taggableTagInputFields = $(taggableTagInputs(index, likenessRank, tagId, taggableType))
          $(`.${category}_tag_fields`).append(taggableTagInputFields)
        }

      })
      .on("remove", ({ detail }) => {
        const tagId = detail.data.value
        const hiddenTagInputs = $(`div[data-tag-id="${tagId}"]`)

        if (hiddenTagInputs.hasClass("existing-tag")) {
          hiddenTagInputs.children(".destroy-checkbox").prop("checked", true)
        } else {
          hiddenTagInputs.remove()
        }
      })

    renderSuggestionsList(tagify)
  })
})
