import { Controller } from "stimulus"
import SlimSelect from "slim-select"
import debounce from "lodash.debounce"

export default class extends Controller {
  initialize() {
    this.fetchChannels = debounce(this.fetchChannels, 300).bind(this)
  }

  // Adapted from https://stackoverflow.com/a/46134583
  formatNumber(number, label) {
    number = parseInt(number)
    const ABBREVS = ["", "K", "M", "B"]
    const i = 0 === number ? number : Math.floor(Math.log(number) / Math.log(1000))
    const result = parseFloat(number / Math.pow(1000, i)).toFixed(i > 0 ? 1 : 0)
    const formattedNumber = i < ABBREVS.length ? `${result}${ABBREVS[i]}` : "> 1T"
    return `${formattedNumber} ${label}`
  }

  fetchChannel(channelId) {
    return fetch(`https://www.googleapis.com/youtube/v3/channels?key=${process.env.YOUTUBE_API_KEY}&id=${channelId}&part=snippet,statistics`)
      .then(response => response.json())
      .then(data => {
        if (data.items) {
          return data.items[0]
        } else {
          return null
        }
      })
  }

  optionHtml(channel, query) {
    const { statistics: { videoCount, subscriberCount }, snippet: { title, thumbnails }} = channel

    // do not remove <span class="is-hidden">${query}</span> as it is needed to be searchable
    return `
    <div class="option">
      <div class="is-clickable is-flex is-flex-direction-row">
        <img class="channel-thumbnail mr-2" src="${thumbnails.default.url}" />
        <div class="is-flex is-flex-direction-column is-align-items-flex-start">
          <div>${title}</div>
          <span class="is-hidden">${query}</span>
          <div class="is-flex is-flex-direction-row">
            ${subscriberCount ? `<div class="is-size-7 mr-2">${this.formatNumber(subscriberCount, "subscribers")}</div>` : ""}
            ${videoCount ? `<div class="is-size-7">${this.formatNumber(videoCount, "videos")}</div>` : ""}
          </div>
        </div>
      </div>
    </div>
  `
  }

  fetchChannels(query, callback) {
    if (query === "") {
      callback(false)
      return
    }

    // https://www.googleapis.com/youtube/v3/search?key=${key}&q=${query}&part=snippet&type=channel&maxResults=5

    this.fetchChannel(query)
      .then(channel => {
        if (channel) {
          return [channel]
        } else {
          return fetch(`https://www.googleapis.com/youtube/v3/search?key=${process.env.YOUTUBE_API_KEY}&q=${query}&part=snippet&type=channel&maxResults=5`)
            .then(response => response.json())
            .then(data => {
              if (data.items) {
                return Promise.all(data.items.map(item => this.fetchChannel(item.id.channelId)))
              } else {
                return Promise.resolve([])
              }
            })
        }
      })
      .then(channels => {
        const options = channels.map(channel => {
          return {
            text: `title ${channel.id}`,
            value: channel.id,
            innerHTML: this.optionHtml(channel, query)
          }
        })

        callback(options)
      })
      .catch((error) => {
        console.log(error)
        callback(false)
      })
  }

  connect() {
    new SlimSelect({
      select: this.element,
      allowDeselect: true,
      ajax: this.fetchChannels,
      placeholder: "Enter Channel ID",
      searchPlaceholder: "Enter Channel ID",
    })
  }
}
