import * as Amplitude from "amplitudejs"
import qs from "qs"

// each song index page is treated as a "playlist"
// the playlist key is just the query strings keys/values as a json string
// example: /songs?q%5Btag_ids%5D%5B%5D=4&q%5Benergies%5D%5B%5D=very_low => "{q:{tag_ids:[4],energies:[very_low]},page:1}"
// see getPlaylistKey() below and PlayerHelper#amplitude_playlist_key
//
// as the user visits each page, the songs on the page gets added (or initalized)
// as a playlist on Amplitude

const resetSongIndexesAndPlaylistKey = (playlistKey) => {
  document.querySelectorAll(".row-play-pause").forEach((el, i) => {
    el.dataset.amplitudeSongIndex = i
    el.dataset.amplitudePlaylist = playlistKey
  })
}

const visibleSongs = () => {
  return $(".song-row").map(function() {
    return {
      title: this.dataset.title,
      artist: this.dataset.artist,
      url: this.dataset.url,
    }
  }).toArray()
}

$(document).on("turbolinks:load", () => {
  if ($(".songs-index").length === 0) return

  const songs = visibleSongs()
  if (songs.length === 0) return

  const playlistKey = $("#amplitude_playlist_key").data("playlistKey")
  const activePlaylist = Amplitude.getActivePlaylist()

  // since the play-pause button has the data-turbolinks-permanent attribute
  // the pause button doesn't change back to the play button
  // when a new song is played from a different page
  // so this checks if the current playing song's playlist key matches the page
  // and switches any pause buttons to play buttons
  if (!!activePlaylist && activePlaylist !== playlistKey) {
    $("td .amplitude-play-pause.amplitude-playing").removeClass("amplitude-playing")
  }

  if (activePlaylist) {
    Amplitude.addPlaylist(playlistKey, {}, songs)
    resetSongIndexesAndPlaylistKey(playlistKey)
    Amplitude.bindNewElements()
  } else {
    Amplitude.init({
      // debug: true,
      continue_next: false,
      starting_playlist_song: 0,
      playlists: {
        [playlistKey]: { songs }
      },
    })

    window.player = Amplitude
  }
})
