import { Controller } from "stimulus"

export default class extends Controller {
  static values = { toggleClass: String }

  static targets = [
    "contentContainer",
    "input"
  ]

  toggle(e) {
    const { checked } = e.target

    if (checked) {
      this.contentContainerTargets.forEach((target) => {
        target.classList.remove(this.toggleClassValue)
      })
    } else {
      this.contentContainerTargets.forEach((target) => {
        target.classList.add(this.toggleClassValue)
      })
    }

    if (this.hasInputTarget) {
      this.inputTargets.forEach(input => { input.disabled = !checked })
    }
  }
}
