$(document).on("turbolinks:load", () => {
  const adminForm = $(".admin-form")
  if (adminForm.length === 0) return false

  $("#admin_manage_users").on("change", (e) => {
    if (e.target.checked) {
      $("#admin_delete_users").prop("disabled", false)
    } else {
      $("#admin_delete_users")
        .prop("checked", false)
        .prop("disabled", true)
    }
  })

  $("#admin_user_is_artist").on("change", (e) => {
    if (e.target.checked) {
      $("#admin_payout_account_id").prop("disabled", false)
      $(".admin_payout_accounts_field").removeClass("is-hidden")
      $(".admin_permissions_field").addClass("is-hidden").find("input").prop("checked", false)
      // Default to "None" for media permissions so record can be saved correclty
      $("#admin_user_media_permissions_none").prop("checked", true)
    } else {
      $("#admin_payout_account_id").prop("disabled", true)
      $(".admin_payout_accounts_field").addClass("is-hidden")
      $(".admin_permissions_field").removeClass("is-hidden")
      $("#admin_user_media_permissions_none").prop("checked", false)
    }
  })
})
