import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tooltip"]
  static values = { text: String }

  copy() {
    navigator.clipboard.writeText(this.textValue)
    this.tooltipTarget.setAttribute("aria-label", "Copied!")

    setTimeout(() => {
      this.tooltipTarget.setAttribute("aria-label", "Copy")
    }, 1000)
  }
}
