import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal"]

  static values = {
    url: String,
  }

  disableScroll() {
    document.documentElement.classList.add("is-clipped")
  }

  enableScroll() {
    document.documentElement.classList.remove("is-clipped")
  }

  open() {
    this.disableScroll()

    if (this.hasModalTarget) {
      this.modalTarget.classList.add("is-active")
    } else {
      if (!this.hasUrlValue) return false

      fetch(this.urlValue)
        .then(response => response.text())
        .then(html => {
          const fragment = document
            .createRange()
            .createContextualFragment(html)

          this.element.appendChild(fragment)
        })
    }
  }

  close() {
    this.enableScroll()

    if (this.hasModalTarget) {
      this.modalTarget.classList.remove("is-active")
    }
  }

  disconnect() {
    this.enableScroll()

    if (this.hasModalTarget) {
      this.modalTarget.remove()
    }
  }
}
