import { Controller } from "stimulus"
import { ajax } from "@rails/ujs"

export default class extends Controller {
  static values = { code: String, url: String }
  static targets = [ "input", "wrapper", "tooltip" ]

  copy() {
    navigator.clipboard.writeText(this.codeValue)
    this.tooltipTarget.setAttribute("aria-label", "Copied!")

    setTimeout(() => {
      this.tooltipTarget.setAttribute("aria-label", "Copy")
    }, 1000)
  }

  codeValueChanged() {
    this.inputTarget.value = this.codeValue

    if (this.hasCodeValue) {
      this.wrapperTarget.classList.replace("is-loading", "has-icons-right")
      this.tooltipTarget.classList.remove("is-hidden")
    }
  }

  clearCode() {
    this.codeValue = ""
  }

  generateCode() {
    if (this.hasCodeValue) {
      this.clearCode()
    }

    this.requestCode()
  }

  requestCode() {
    ajax({
      type: "post",
      dataType: "json",
      url: this.urlValue,
      success: (data) => this.codeValue = `Code: ${data.code}`,
      error: (_data) => { alert("Error: YouTube Code generation failed.") }
    })
  }
}
