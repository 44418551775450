$(document).on("turbolinks:load", () => {
  const audioGroups = $(".pause-audio-tags")
  if (audioGroups.length === 0) return false

  for (let audioGroup of audioGroups) {
    audioGroup.addEventListener("play", (e) => {
      const audioTags = audioGroup.getElementsByTagName("audio")

      for (let audioTag of audioTags) {
        if (audioTag !== e.target) audioTag.pause()
      }
    }, true)
  }
})
