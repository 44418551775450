import Uppy from "@uppy/core"
import DragDrop from "@uppy/drag-drop"
import StatusBar from "@uppy/status-bar"
import Transloadit from "@uppy/transloadit"

import "@uppy/core/dist/style.css"
import "@uppy/drag-drop/dist/style.css"
import "@uppy/status-bar/dist/style.css"

$(document).on("turbolinks:load", () => {
  if ($(".song-form").length === 0) return false

  $(".delete-existing-audio-file").on("click", function () {
    $(this).children(".destroy-checkbox").prop("checked", true)
    $(this).parents(".existing-audio-file").fadeOut()
  })

  const limitPrimaryAudioFile = () => {
    $(".primary-or-alternate-radio").on("change", function (e) {
      e.preventDefault()
      const primaryChecked = e.target.value === "1"

      // prevent other audio files from being marked primary
      if (primaryChecked) {
        $(".primary-radio").prop("checked", false)
        $(".alternate-radio").prop("checked", true)
        $(".alternate-section-select").prop("disabled", false)
        $(this).prop("checked", true)
      }

      // disable alternate select dropdown if primary checked
      $(this)
        .closest(".control")
        .find(".alternate-section-select")
        .prop("disabled", primaryChecked)
    })
  }

  limitPrimaryAudioFile()

  const audioFileFields = (index, transloaditFile, assemblyId) => {
    const { ext, original_name, meta: { duration } } = transloaditFile

    return `
      <div class="control mt-4 is-flex is-align-items-center">
        <label class="mr-5">
          <input
            required
            type="radio"
            value="1"
            class="primary-or-alternate-radio primary-radio"
            name="song[audio_files_attributes][${index}][primary]"
          >
          Primary
        </label>
        <label class="mr-5">
          <input
            type="radio"
            value="0"
            class="primary-or-alternate-radio alternate-radio"
            name="song[audio_files_attributes][${index}][primary]"
          >
          Alternate
        </label>
        <div class="select is-small">
          <select
            name="song[audio_files_attributes][${index}][alternate_section]"
            class="alternate-section-select"
            disabled
          >
            <option value="">select section</option>
            <option value="verse">verse</option>
            <option value="chorus">chorus</option>
            <option value="bridge">bridge</option>
            <option value="full">full</option>
            <option value="intro">intro</option>
          </select>
        </div>
      </div>
      <div class="control mt-4 uploaded-audio-file is-flex-direction-column">
      <div class="control is-flex">
        <label class="mr-5">
          <input
            required
            type="radio"
            value="instrumental"
            name="song[audio_files_attributes][${index}][vocal_degree]"
          >
          Instrumental
        </label>
        <label class="mr-5">
          <input
            type="radio"
            value="background_vocals"
            name="song[audio_files_attributes][${index}][vocal_degree]"
          >
          Background Vocals + Music
        </label>
        <label class="mr-5">
          <input
            type="radio"
            value="lead_vocal"
            name="song[audio_files_attributes][${index}][vocal_degree]"
          >
          Lead Vocals + Background Vocals + Music
        </label>
        </div>
        <div class="control is-flex mt-4 mr-5">
        <label>
        <input
          type="checkbox"
          class="clean-version-checkbox"
          name="song[audio_files_attributes][${index}][clean_version]"
        >
          Clean Version <small>(only applies if primary version is explicit)</small>
        </label>
        </div>
      </div>

      <label class="mt-5 mb-2 is-size-7">
        If this file is a special version that excludes certain instruments,
        mark which instruments below (a.k.a. select drums if this audio file
        is a version of the song without drums)
      </label>
      <div class="select is-small hide-arrow">
        <input name="song[audio_files_attributes][${index}][exclusions][]" type="hidden">
        <select
          name="song[audio_files_attributes][${index}][exclusions][]"
          class="instrument-select" multiple
          data-controller="slim-select"
          data-slim-select-options-value={"closeOnSelect":false}
        >
          ${$(".instrument-options").prop("innerHTML")}
        </select>
      </div>
      <input type="hidden" value="${assemblyId}" name="song[audio_files_attributes][${index}][transloadit_assembly_id]">
      <input type="hidden" value=${duration} name="song[audio_files_attributes][${index}][duration]">
      <input type="hidden" value="${ext}" name="song[audio_files_attributes][${index}][file_extension]">
      <input type="hidden" value="${original_name}" name="song[audio_files_attributes][${index}][original_filename]">
    `
  }

  const addedAudioFileDiv = (filename, uppyFileId) => {
    return `
      <div
        class="field-body box is-flex-direction-column mb-4 audio-file-box"
        data-uppy-file-id=${uppyFileId}
        data-file-name="${filename}"
      >
        <div class="filename-wrapper is-flex is-justify-content-space-between">
          <div class="is-family-monospace truncate-700">${filename}</div>
          <div class="delete"></div>
        </div>
      </div>
    `
  }

  const addAudioFileDiv = (uppyFile) => {
    $(".existing-audio-files").append($(addedAudioFileDiv(uppyFile.name, uppyFile.id)))

    $(`[data-uppy-file-id='${uppyFile.id}'] .filename-wrapper .delete`).on("click", () => {
      uppy.removeFile(uppyFile.id)
    })

    uppy.setFileMeta(uppyFile.id, {
      authenticity_token: $("input[name='authenticity_token']").val(),
      song_id: $("#song_id").val(),
    })
  }

  const addAudioFileFields = (transloaditFile, assemblyId) => {
    const addedFile = $(`[data-file-name='${transloaditFile.name}'] .filename-wrapper`)
    const index = $(".existing-audio-file").length + $(".uploaded-audio-file").length

    $(audioFileFields(index, transloaditFile, assemblyId)).insertAfter(addedFile)
    limitPrimaryAudioFile()
  }

  const showUploadError = (uppyFileId, _error, response) => {
    const errorMsg = response.body.error
    const errorMsgDiv = `<div class="help is-inline is-danger">${errorMsg} Please remove and reupload.</div>`
    const fileNameWrapperdiv = $(`[data-uppy-file-id='${uppyFileId}'] .filename-wrapper`)
    $(errorMsgDiv).insertAfter(fileNameWrapperdiv)
  }

  const removeFile = (uppyFileId) => {
    uppy.removeFile(uppyFileId)
    $(`[data-uppy-file-id='${uppyFileId}']`).remove()
  }

  const uppy = new Uppy({
    allowMultipleUploads: true,
    debug: true,
    autoProceed: true,
    restrictions: {
      allowedFileTypes: [".wav"],
    }
  })

  uppy
    .use(Transloadit, {
      waitForMetadata: true,
      params: {
        auth: { key: process.env.TRANSLOADIT_API_KEY },
        template_id: process.env.TRANSLOADIT_AUDIO_FILE_TEMPLATE_ID
      }
    })
    .use(DragDrop, {
      target: ".audio-files-uploader",
      height: "175px",
      note: "Audio files must be 48k/24-bit or 44.1k/16-bit AND either mastered through LANDR or self-mastered at -7LUFS",
    })
    .use(StatusBar, {
      target: ".audio-files-progress-bar",
      showProgressDetails: true,
      hideUploadButton: true,
      hideRetryButton: true,
      showProgressDetails: true,
      hideAfterFinish: false,
    })
    .on("file-added", (uppyFile) => {
      addAudioFileDiv(uppyFile)
    })
    .on("transloadit:upload", (transloaditFile, assembly) => {
      addAudioFileFields(transloaditFile, assembly.assembly_id)
    })
    .on("file-removed", (uppyFile) => {
      removeFile(uppyFile.id)
    })
    .on("upload-error", (uppyFile, _error, response) => {
      showUploadError(uppyFile, _error, response)
    })
})
