const images = require.context("../images", true)
const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
require("turbolinks").start()
require("@nathanvda/cocoon")
require("@yaireo/tagify/src/tagify.scss")

import "../stylesheets/application.scss"

import "controllers"
import "../adminForm"
import "../adminMobileNavigation"
import "../apiKeyForm"
import "../userDocuments"
import "../flashMessage"
import "../offerForm"
import "../pauseAudioTags"
import "../player"
import "../marketplacePlayer"
import "../marketplaceShowPlayer"
import "../playlistForm"
import "../songAudioFileFields"
import "../generateDaaciCsvForm"
import "../songTagField"
import "../turbolinksScrollTop"

$(document).on("turbolinks:load", function () {
  $.rails.refreshCSRFTokens()

  // this is needed since GET forms don't work by default with turbolinks
  // https://github.com/turbolinks/turbolinks-ios/issues/132
  $("form[method=get][data-remote=true]").on("submit", function (e) {
    e.preventDefault()
    const url = this.action + "?" + $(this).serialize()
    Turbolinks.visit(url)
    false
  })
})
