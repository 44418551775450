export const fetchAndPlayAudio = (trackId, player) => {
  const url = "/app/marketplace/songs/get_stream"
  const headers = {
    "Content-Type": "application/vnd.api+json",
    "Accept": "application/vnd.api+json",
  }

  const body = JSON.stringify({ track_id: trackId })

  fetch(url, {
    method: "POST",
    headers: headers,
    body: body
  })
  .then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.text()
  })
  .then(url => {
    if (url) {
      player.src = url
      player.play()
    } else {
      console.error("Error fetching audio URL: No URL returned")
    }
  })
  .catch(error => {
    console.error("Error:", error.message)
  })
}
