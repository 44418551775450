$(document).on("turbolinks:load", () => {
  const form = $(".api-key-form")
  if (form.length === 0) return false

  $("select#api_key_kind").on("change", function () {
    const sfx_checkbox = $("#api_key_include_sound_effects")
    if (["Test", "Trial"].includes(this.value)) {
      sfx_checkbox.prop("checked", false)
      sfx_checkbox.closest(".field").addClass("disabled")
    } else {
      sfx_checkbox.closest(".field").removeClass("disabled")
    }
  })
})
