import { Controller } from "stimulus"

export default class extends Controller {
  static classes = [
    "volumeOff",
    "volumeDown",
    "volumeUp",
  ]

  static targets = [
    "volumeIcon",
  ]

  showPlayer() {
    $(".player").removeClass("is-hidden")
  }

  updateVolumeIcon(e) {
    const value = parseInt(e.target.value)
    const volumeIcon = this.volumeIconTarget
    const { volumeUpClass, volumeOffClass } = this

    if (value > 60) {
      volumeIcon.classList.add(volumeUpClass)
      volumeIcon.classList.remove(volumeOffClass)
    } else if (value === 0) {
      volumeIcon.classList.add(volumeOffClass)
      volumeIcon.classList.remove(volumeUpClass)
    } else {
      volumeIcon.classList.remove(volumeUpClass, volumeOffClass)
    }
  }
}
