import { Controller } from "stimulus"
import Tagify from "@yaireo/tagify"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = {
    isSortable: Boolean,
  }

  connect() {
    const tagify = new Tagify(this.element, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(",")
    })

    if (this.isSortableValue) {
      new Sortable(tagify.DOM.scope, {
        animation: 150,
        easing: "cubic-bezier(1, 0, 0, 1)",
        forceFallback: true,
        onEnd: () => tagify.updateValueByDOMTags(),
      })
    }
  }
}
